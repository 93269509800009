import React from 'react';
import { PageProps } from 'gatsby';
import { StrapiImprint } from '../graphql-types';
import TitleBox from '../components/title-box';
import { Row, Col, Container } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Imprint: React.FC<PageProps<never, { strapiImprint: StrapiImprint }>> = ({
  pageContext: {
    strapiImprint: { title, content },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} />

      <main>
        <TitleBox>
          <h1>{title}</h1>
        </TitleBox>
        <Container className="pt-5">
          <Row>
            <Col>
              <ReactMarkdown source={content} />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default Imprint;
